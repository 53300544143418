import * as React from "react";
import { useSelector } from "react-redux";
import { Stack, Box, Typography } from "@mui/material";
import Image from "next/image";
import calendarStyles from "./date.filter.module.css";
import LocationIcon from "../../public/images/icons/location_pin_grey.svg";
import LocationAutoComplete from "../location-auto-complete";
import {
  SEARCH_ENTITY_TYPE,
  useSearchFilterUrlManager,
} from "../search/common";
import FilterSuggestion from "../filter/components/filter-suggestion";

// TODO: Merge with search.filters.section
export default function ResponsiveDatePicker({
  searchFilters,
  setDestinationFn,
  suggestedLocations = [],
}) {
  const destinationLocation = useSelector(
    (state) => state.FilterReducer.destinationLocation?.details
  );
  const filterHandler = useSearchFilterUrlManager({
    entityType: SEARCH_ENTITY_TYPE.LOCATION,
    replaceUrl: false,
  });

  function handleDestinationChange(newDestinationLocation) {
    filterHandler({
      id: newDestinationLocation.id,
      name: newDestinationLocation.name,
      description: newDestinationLocation.description,
      url_path: newDestinationLocation.urlPath,
      trips_url_path: newDestinationLocation.tripsUrlPath,
      photos: newDestinationLocation.photos,
    });
  }

  return (
    <Box className={calendarStyles.responsiveCalendarContainer}>
      <Stack className={calendarStyles.filterOptions_container}>
        <Box className={calendarStyles.calenderDestinationFilterCtn}>
          <LocationAutoComplete
            textFieldSize="medium"
            textFieldLabel="Search destinations"
            location={destinationLocation}
            setLocationFn={handleDestinationChange}
            InputAdornmentIcon={<Image src={LocationIcon} />}
            sx={{
              backgroundColor: "white",
              borderRadius: "8px",
              "& .MuiAutocomplete-inputRoot": {
                paddingRight: "16px !important",
              },
              "& .MuiInputBase-input": {
                height: "27px",
                paddingLeft: "8px !important",
                color: "#94A3B8",
              },
            }}
            InputLabelSx={{
              paddingLeft: "3px",
              paddingRight: "3px",
              fontSize: "16px",
              fontWeight: 400,
              color: "#94A3B8",
              "&:not(.Mui-focused)": {
                paddingTop: "2px",
              },
            }}
          />
          <Box
            className={calendarStyles.calenderDestinationFilter__suggestionsCtn}
          >
            <Typography
              className={
                calendarStyles.calenderDestinationFilter__suggestionsTitle
              }
            >
              Suggested Destinations
            </Typography>
            <FilterSuggestion
              chipClassName={
                calendarStyles.calenderDestinationFilter__suggestionsChip
              }
              chipSelectedClassName={
                calendarStyles.calenderDestinationFilter__suggestionsChip_selected
              }
              namekey="name"
              suggestionEntity="location"
              suggestions={suggestedLocations}
              setSuggestionFn={handleDestinationChange}
              selectedEntityId={destinationLocation?.id}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
