import { Box, Container, Grid, Typography } from "@mui/material";
import { SECTION } from "../google-analytics/constants";
import SeeMoreTrips from "../trip/components/see.more.trips";
import TripSnippet from "../trip/trip.snippet";
import styles from "./upcoming.trips.module.css";

export default function UpcomingTripSection({
  // location,
  trips,
  wrapperBoxStyles,
  gaSource,
}) {
  return (
    <Box className={wrapperBoxStyles}>
      <Container className={styles.upcoming_trips_sections_ctn}>
        <Box>
          <Typography
            className={styles.upcoming_trips_section_header}
            component="h2"
          >
            Upcoming trips
          </Typography>
          <Typography className={styles.upcoming_trips_section_subheader}>
            Discover your next trip - adventure, leisure, tour & more
          </Typography>
        </Box>
        <Box>
          <Box className={styles.upcoming_trips_section_cardsCtn}>
            <Grid container spacing={2} justifyContent="center">
              {trips?.map((trip, index) => (
                <Grid key={index} item xs={12} sm={3} md={3}>
                  <TripSnippet trip={trip} key={trip.id} gaSource={gaSource} />
                </Grid>
              ))}
            </Grid>
          </Box>
          {trips?.length >= 4 && (
            <SeeMoreTrips eventSection={SECTION.HOME_PAGE} />
          )}
        </Box>
      </Container>
    </Box>
  );
}
