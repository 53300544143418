import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import TripSnippet from "../trip/trip.snippet";
import SeeMoreTrips from "../trip/components/see.more.trips";
import { SECTION } from "../google-analytics/constants";
import styles from "./upcoming.trips.module.css";

function UpcomingHolidayTripsSection({
  trips,
  sectionHeader,
  sectionSubHeader,
  seeMoreQueryParams,
  gaSource,
}) {
  return (
    <Box className={styles.upcoming_holiday_trips_section_ctn}>
      <Container>
        <Typography
          className={styles.upcoming_holiday_trips_section_header}
          component="h2"
        >
          {sectionHeader}
        </Typography>
        <Typography className={styles.upcoming_holiday_trips_section_subheader}>
          {sectionSubHeader}
        </Typography>
        <Box className={styles.upcoming_trips_section_cardsCtn}>
          <Grid container spacing={2} justifyContent="center">
            {trips?.map((trip, index) => (
              <Grid key={index} item xs={12} sm={3} md={3}>
                <TripSnippet trip={trip} key={trip.id} gaSource={gaSource} />
              </Grid>
            ))}
          </Grid>
        </Box>
        {trips?.length >= 4 && (
          <SeeMoreTrips
            hrefQueryParams={seeMoreQueryParams}
            eventSection={SECTION.HOME_PAGE}
          />
        )}
      </Container>
    </Box>
  );
}

export default UpcomingHolidayTripsSection;
