import React from "react";
import { Card, Typography, Stack, Grid } from "@mui/material";
import style from "./testimonal_card.module.css";
import Image from "next/image";
import { Box } from "@mui/system";
import goldStar_img from "../../../../public/images/icons/gold_star.svg";
import customerImg from "../../../../public/images/cohyk.png";
import customerImgAkhil from "../../../../public/images/user_akhil.jpg";
import customerImgNaina from "../../../../public/images/user_naina.jpg";
import customerImgTuhina from "../../../../public/images/user_tuhina.jpg";

const getCustomerAvatar = (username) => {
  switch (username) {
    case "Akhil":
      return customerImgAkhil;
    case "Naina":
      return customerImgNaina;
    case "Tuhina":
      return customerImgTuhina;
    default:
      return customerImg;
  }
};

const TestimonalCard = ({ username, children }) => {
  const customerAvatar = getCustomerAvatar(username);
  return (
    <Card
      className={style.testimonal_card}
      sx={{
        width: {
          xs: "100%",
          md: "35%",
          lg: "35%",
        },
      }}
    >
      <Stack className={style.testimonalCards_top} gap="20px" direction="row">
        <Box className={style.customerImg}>
          <Image
            src={customerAvatar}
            width="72px"
            height="72px"
            alt={username + " testimonial image"}
          />
        </Box>
        <Stack className={style.customer_info} direction="column" gap="10px">
          <Typography variant="text-xl-semibold" component={"h3"}>
            {username}
          </Typography>
          {/* <Typography variant="text-sm" color="#969696">
            Manager
          </Typography> */}
        </Stack>
      </Stack>
      <Box className={style.testimonalCard_desc}>{children}</Box>
      <Stack className={style.testimonalCard_ratings} direction="row" gap="4px">
        <Image src={goldStar_img} alt="Star rating" priority />
        <Image src={goldStar_img} alt="Star rating" priority />
        <Image src={goldStar_img} alt="Star rating" priority />
        <Image src={goldStar_img} alt="Star rating" priority />
        <Image src={goldStar_img} alt="Star rating" priority />
      </Stack>
    </Card>
  );
};

export default TestimonalCard;
