import { useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { useRouter } from "next/router";
import Search from "@mui/icons-material/Search";
import styles from "./search.trip.module.css";
import { captureEvent } from "../google-analytics/tag";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import { createQueryString, createURL } from "../../utils/string";

export default function SearchTripSection({ searchFilters }) {
  const router = useRouter();
  const reduxState = useSelector((state) => state.FilterReducer);
  const searchTrips = (reduxState, searchFilters) => {
    let basePath = "/search/trips";
    const filterQueries = {
      "start-date": reduxState?.startDate,
    };
    if (
      searchFilters?.tripType?.details?.slug &&
      searchFilters?.tripType?.details?.slug != "any"
    ) {
      basePath = searchFilters?.tripType?.details?.tripsUrlPath;
    }
    if (reduxState?.destinationLocation?.details?.id) {
      if (basePath === "/search/trips") {
        basePath = reduxState?.destinationLocation?.details?.tripsUrlPath;
      } else {
        filterQueries["destination"] =
          reduxState?.destinationLocation?.details?.id;
      }
    }

    captureEvent(EVENTNAME.SEARCH_TRIPS_CLICK, SECTION.HOME_PAGE);
    const queryParams = createQueryString(filterQueries, true);
    const routeToURL = createURL(basePath, queryParams);
    router.push(routeToURL);
  };

  return (
    <Box className={styles.searchTrip__box}>
      <Button
        className={styles.searchTrips__button__box}
        onClick={() => {
          searchTrips(reduxState, searchFilters);
        }}
      >
        <Box className={styles.searchTrips__button} component="span">
          <Typography variant="text-base-semibold" paddingRight={1}>
            Look for Trips
          </Typography>
          <Search />
        </Box>
      </Button>
    </Box>
  );
}
