import React from "react";
import useIsMobile from "../../../utils/device.type.hook";
import ALink from "../../ui/link";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import styles from "./featured.host.module.css";

function FeaturedHostCard({ hostDetail }) {
  const isMobile = useIsMobile();

  return (
    <ALink
      href={hostDetail?.urlPath}
      aProps={{
        className: styles.featuredHostCard,
        target: isMobile ? "_self" : "_blank",
      }}
    >
      <Box className={styles.featuredHostCard__imgCtn}>
        <Image
          src={
            isMobile
              ? `${hostDetail?.photoURL}?tx=w_185,h_185`
              : hostDetail?.photoURL
          }
          layout="fill"
        />
      </Box>
      <Box className={styles.featuredHostCard__detailsCtn}>
        <Typography className={styles.featuredHostCard__detailsCtn__name}>
          {hostDetail?.name}
        </Typography>
        <Box className={styles.featuredHostCard__detailsCtn__bioCtn}>
          <Typography className={styles.featuredHostCard__detailsCtn__bio}>
            {hostDetail?.about?.split("\n")[0]}
          </Typography>
        </Box>
      </Box>
    </ALink>
  );
}

export default FeaturedHostCard;
