import React, { useState } from "react";
import { handleGetApiCall } from "../../utils/api.call";
import { parseLocationObject } from "../location/location";
import AutoCompleteComponent from "../filter/autocomplete";

export default function LocationAutoComplete({
  location,
  setLocationFn,
  textFieldLabel = "Search Location",
  InputAdornmentIcon = null,
  sx = {},
  InputLabelSx = {},
  textFieldSize,
  onChangeFn = () => {},
}) {
  const [locations, setLocations] = useState([]);
  const [destinationSearchText, setDestinationSearchText] = React.useState("");
  const cancelSource = React.useRef(undefined);

  const getSearchedLocation = async (arg__location) => {
    const handleSuccessFn = (res) => {
      let location__array = [];
      res.data?.map((location__obj) => {
        location__array.push(parseLocationObject(location__obj));
      });
      setLocations(location__array);
    };
    if (arg__location.length > 1) {
      await handleGetApiCall(
        "search/locations?q=" + arg__location,
        { cancelSource: cancelSource },
        handleSuccessFn
      );
    }
  };

  function handleDestinationSearchChange(e) {
    setDestinationSearchText(e.target.value);
  }

  return (
    <>
      <AutoCompleteComponent
        options={locations}
        value={location}
        onSelectFn={(_, newVal) => {
          if (newVal) {
            setLocationFn(newVal);
          }
        }}
        onChangeFn={(e) => {
          onChangeFn(e);
          handleDestinationSearchChange(e);
          getSearchedLocation(e.target.value);
        }}
        textFieldLabel={textFieldLabel}
        freeSolo={false}
        disableClearable
        InputAdornmentIcon={InputAdornmentIcon}
        sx={sx}
        InputLabelSx={InputLabelSx}
        textFieldSize={textFieldSize}
        noOptionsText={
          destinationSearchText?.length > 1
            ? "No options"
            : "Type something, min 2 characters"
        }
      />
    </>
  );
}
