import React, { forwardRef } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import styles from "./testimonals.module.css";
import TestimonalCard from "./testimonal_cards/testimonal_card";

const TestimonalSection = forwardRef(({ className = "" }, ref) => {
  return (
    <Box
      className={[styles.testimonal_container, className].join(" ")}
      ref={ref}
    >
      <Container sx={{ padding: 0, margin: "0 auto" }}>
        <Typography className={styles.testimonal_heading} component={"h2"}>
          Cohyk is loved by users
        </Typography>
        <Stack
          className={styles.testimonal_cards}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <TestimonalCard username={"Naina"}>
            <Typography variant="text-sm" color="#475569">
              My husband and I have been wanting to go to Spiti Valley since
              forever. Googling and watching dozens of trip ads on Instagram and
              then talking to hosts took a lot of time. Thanks to a friend who
              suggested Cohyk, we found the perfect host for our trip. Can't
              wait to travel more this year!
            </Typography>
          </TestimonalCard>
          <TestimonalCard username={"Tuhina"}>
            <Typography variant="text-sm" color="#475569">
              This goes out to Sachin and his team for organising the most
              special trip for us through the Western Ghats. It felt like a
              dream to be there during the monsoons. Thanks to Cohyk which
              helped us discover Sachin and his team who could design the
              perfect trip for me and my family.
            </Typography>
          </TestimonalCard>
          <TestimonalCard username={"Akhil"}>
            <Typography variant="text-sm" color="#475569">
              I usually travel with friends but found them busy this time. I
              decided to go on my first group trip. Found the hiking trip to
              Badrinath Temple on Cohyk for a group of 10. Honestly, if it
              wasn't for the fellow hikers, it wouldn't be as fun.
            </Typography>
          </TestimonalCard>
        </Stack>
      </Container>
    </Box>
  );
});

export default TestimonalSection;
