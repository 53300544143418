import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TripTypeCard from "./trip.type.card";
import styles from "./trip.type.module.css";

function TripTypeCardSection({ tripTypes = [], wrapperBoxClassName = "" }) {
  return (
    <Box className={wrapperBoxClassName}>
      <Container>
        <Box className={styles.tripTypeSection}>
          <Box className={styles.tripTypeSection__headerCtn}>
            <Typography
              component="h2"
              className={styles.tripTypeSection__header}
            >
              Explore trips by Experiences
            </Typography>
            <Typography className={styles.tripTypeSection__subHeader}>
              Discover trips categorized by the experiences they offer
            </Typography>
          </Box>

          <Box className={styles.tripTypeSection__cardsCtn}>
            {tripTypes.map((tripType) => (
              <TripTypeCard key={tripType?.id} tripType={tripType} />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default TripTypeCardSection;
