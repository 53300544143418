import { Box, Stack, Typography, Container } from "@mui/material";
import PropTypes from "prop-types";
import Location from "../../location/location";
import React from "react";
import LocationCard from "../../location/location.card";
import styles from "./popular.module.css";

/**
 * PopularLocations component is a section component with following props.
 *
 * - locations: list of popular location, not being used
 * - srcLocation: location in which context popular location should work, not being used
 * - wrapperBoxStyles: to set style to Box wrapping the component
 *
 * @example
 * <PopularLocations locations={[]} srcLocation={location} />
 *
 * @returns
 */
export default function PopularLocations({
  wrapperBoxStyles,
  locations,
  srcLocation,
  useAltTitleDesign = false,
}) {
  if (locations.length <= 0) {
    return <></>;
  }

  return (
    <Box className={wrapperBoxStyles}>
      <Container>
        <Typography
          className={[
            styles.popularLocation__header,
            useAltTitleDesign && styles.popularLocation__header_alt,
          ].join(" ")}
          component="h2"
        >
          Popular trip destinations
        </Typography>
        <Typography
          className={[
            styles.popularLocation__subheader,
            useAltTitleDesign && styles.popularLocation__subheader_alt,
          ].join(" ")}
        >
          Destinations which are popular among travellers and trip hosts
        </Typography>
        <Box className={styles.popularLocation__stackCtn}>
          <Stack className={styles.popularLocation__stack}>
            {locations.map((loc, index) => (
              <LocationCard key={loc?.id ?? index} locationDetail={loc} />
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

PopularLocations.propTypes = {
  wrapperBoxStyles: PropTypes.string,
  locations: PropTypes.arrayOf(Location),
  srcLocation: PropTypes.objectOf(Location),
};
