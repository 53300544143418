import { useState } from "react";
import ResponsiveDatePicker from "../calendar/date.filter";
// import TripActivities from "../trips-category";
import SearchTripSection from "./search.trip.section";
import { HighlightLocationFilterObj } from "../filter/destination-filter/highlight.location.filter.obj";
import { TripTypeFilterObj } from "../filter/trip-type-filter/trip.type.filter.obj";
import { useSelector } from "react-redux";

const SearchFiltersSection = ({
  popularTripActivities,
  suggestedLocations,
}) => {
  const filterDestination = useSelector(
    (state) => state.FilterReducer.destinationLocation?.details
  );
  const initialState = {
    destination: filterDestination || HighlightLocationFilterObj.defaultValue,
    tripType: TripTypeFilterObj.defaultValue,
  };
  const [searchFilters, setSearchFilters] = useState(initialState);

  const setFilterDestination = (destination) => {
    setSearchFilters((state) => {
      return { ...state, destination: destination };
    });
  };
  // const setFilterTripType = (tripType) => {
  //   setSearchFilters((state) => {
  //     return { ...state, tripType: tripType };
  //   });
  // };

  return (
    <>
      <ResponsiveDatePicker
        searchFilters={searchFilters}
        setDestinationFn={setFilterDestination}
        suggestedLocations={suggestedLocations}
      />
      {/* <TripActivities
        setTripTypeFn={setFilterTripType}
        popularTripActivities={popularTripActivities}
      /> */}
      <SearchTripSection searchFilters={searchFilters} />
    </>
  );
};

export default SearchFiltersSection;
