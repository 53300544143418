import React, { useState, useEffect } from "react";
import { parseTripObject } from "../components/trip/trip";
import HomeLayout from "../layouts/home.layout";
import { handleGetApiCall, handlePostApiCall } from "../utils/api.call";
import { getCurrentDate } from "../utils/date";
import { parseLocationObject } from "../components/location/location";
import PageHead from "../components/head/pagehead";
import MetaTags from "../components/head/metatags";
import { SEARCH_CONTEXT } from "../utils/search.context";
import { cookieHeaderString, getApiResponseCookies } from "../utils/cookie";
import { parseHostObject } from "../components/Host/host";
import { parseTripTypeObj } from "../components/filter/trip.type.class";

const commonTripSearchParams = {
  start_date: getCurrentDate(),
  limit: 4,
  context: SEARCH_CONTEXT.HOME_PAGE,
};

const commonTripData = {
  baseSearchUrl: "/search/trips",
};

const upcomingTripSectionsData = [
  {
    ...commonTripData,
    searchUrl: "search/trips",
    searchParams: {
      ...commonTripSearchParams,
      women_only: true,
      context: SEARCH_CONTEXT.HOME_PAGE_WOMEN_ONLY_TRIPS,
    },
    sectionHeader: "Women only trips",
    sectionSubHeader: "Trips & experiences with like-minded group of women",
    seeMoreQueryParams: "women-only=true",
    trips: [],
  },
  {
    ...commonTripData,
    // custom search link
    baseSearchUrl: "/photography-trips",
    // searchUrl: "search/trips",
    searchParams: {
      ...commonTripSearchParams,
      trip_type: 11,
      context: SEARCH_CONTEXT.HOME_PAGE_PHOTOGRAPHY_TRIPS,
    },
    sectionHeader: "Photography trips",
    sectionSubHeader:
      "Guided trips with experienced photographers for photographers",
    // seeMoreQueryParams: "trip-type=photography",
    trips: [],
  },
  {
    ...commonTripData,
    // custom search link
    baseSearchUrl: "/hike-trips",
    // searchUrl: "search/trips",
    searchParams: {
      ...commonTripSearchParams,
      trip_type: 5,
      context: SEARCH_CONTEXT.HOME_PAGE_HIKE_TRIPS,
    },
    sectionHeader: "Trekking/Hiking trips",
    sectionSubHeader: "Discover the India’s most scenic and adventurous trails",
    // seeMoreQueryParams: "trip-type=hike",
    trips: [],
  },
  // {
  //   ...commonTripData,
  //   baseSearchUrl:
  //     "/location/kedarkantha-uttarakhand-india/upcoming-trips",
  //   searchUrl: "search/trips/location",
  //   searchParams: {
  //     ...commonTripSearchParams,
  //     highlight_location_id: 76,
  //     context: SEARCH_CONTEXT.HOME_PAGE_LOCATION_TRIPS,
  //   },
  //   sectionHeader: "Trips to Kedarkantha",
  //   sectionSubHeader:
  //     "Destinations which are popular among travellers and trip hosts",
  //   seeMoreQueryParams: "destination=76",
  //   trips: [],
  // },
];

const upcomingHolidaySectionData = {
  searchUrl: "search/trips",
  searchParams: {
    ...commonTripSearchParams,
    start_date: "2023-06-29",
    context: SEARCH_CONTEXT.HOME_PAGE_HOLIDAY_TRIPS,
  },
  sectionHeader: "Long weekend trips on this Eid-ul-Adha",
  sectionSubHeader: "Starting from 29th June, Thursday. Take Friday off.",
  seeMoreQueryParams: "start-date=2023-06-29",
  trips: [],
};

export default function Index({
  // popularTripActivities,
  tripTypeMap,
  tripTypeArr,
  // queryParams,
  upcomingTrips,
  // popularLocations,
  upcomingTripSectionsData,
  upcomingHolidaySectionData,
  suggestedLocations,
}) {
  const [featuredHosts, setFeaturedHosts] = useState([]);
  const [popularLocations, setPopularLocations] = useState([]);

  useEffect(() => {
    function handleSuccessFn(res) {
      setFeaturedHosts(res?.data?.map((host) => parseHostObject(host)));
    }
    function handlePopularLocationsSuccessFn(res) {
      setPopularLocations(
        res?.data?.locations?.map((location) => parseLocationObject(location))
      );
    }

    handleGetApiCall("host/featured-hosts", {}, handleSuccessFn);
    handleGetApiCall("location/popular", {}, handlePopularLocationsSuccessFn);
  }, []);

  const pageTitle =
    "Find trips by trustworthy hosts to connect and travel with";
  const pageImg = "https://cohyk.mo.cloudinary.net/op-v1/images/logo.png";

  const metaParams = new MetaTags({
    description:
      "Find upcoming trips and experiences by amazing and experienced hosts within budget to popular and offbeat places in India. Contact 250+ hosts directly to book from 2000+ trips.",
    // Cohyk is a unique and the only platform to discover over 1000+ trips to 150+ destinations in India. You can connect with 150+ trustworthy hosts directly for experiences, tours, fun, adventure and spiritual trips with highest standard of safety and security.
    imageURL: pageImg,
    permalink: "https://cohyk.com",
    keywords: [
      "upcoming trips",
      "adventure trips",
      "trips near me",
      "trips from New Delhi",
      "spiritual trips",
      "hiking trips",
      "trekking trips",
    ],
  });

  return (
    <>
      <PageHead pageTitle={pageTitle} metaTagParams={metaParams} />
      <HomeLayout
        // popularTripActivities={popularTripActivities}
        tripTypeMap={tripTypeMap}
        tripTypeArr={tripTypeArr}
        upcomingTrips={upcomingTrips}
        popularLocations={popularLocations}
        upcomingCategoryTripsData={upcomingTripSectionsData}
        upcomingHolidayTripsData={upcomingHolidaySectionData}
        featuredHosts={featuredHosts}
        suggestedLocations={suggestedLocations}
      />
    </>
  );
}

export async function getServerSideProps(ctx) {
  const { req, res } = ctx;
  let cookieString = cookieHeaderString(req.cookies);
  let resCookies = [];

  let upcomingTrips = [];
  const handleTripsSuccessFn = (res) => {
    resCookies = getApiResponseCookies(res, resCookies);
    return res.data;
  };

  const tripSearchParams = {
    ...commonTripSearchParams,
    context: SEARCH_CONTEXT.HOME_PAGE_ALL_TRIPS,
  };

  const response =
    (await handlePostApiCall({
      url: "search/trips",
      params: tripSearchParams,
      handleSuccessFn: handleTripsSuccessFn,
      headers: {
        cookie: cookieString,
      },
    })) || [];
  upcomingTrips = response.trips?.map((trip) => parseTripObject(trip));

  // category sections
  const categorySectionTripsCalls = upcomingTripSectionsData.map(
    (upcomingSectionData) => {
      return handlePostApiCall({
        url: "search/trips",
        params: upcomingSectionData.searchParams,
        handleSuccessFn: handleTripsSuccessFn,
        headers: {
          cookie: cookieString,
        },
      }).then((upcomingTripsResult) => {
        upcomingSectionData.trips = upcomingTripsResult?.trips?.map((trip) =>
          parseTripObject(trip)
        );
      });
    }
  );
  // Holiday section
  if (
    new Date(upcomingHolidaySectionData.searchParams.start_date) > new Date()
  ) {
    categorySectionTripsCalls.push(
      handlePostApiCall({
        url: upcomingHolidaySectionData.searchUrl,
        params: upcomingHolidaySectionData.searchParams,
        handleSuccessFn: handleTripsSuccessFn,
        headers: {
          cookie: cookieString,
        },
      }).then((upcomingTripsResult) => {
        upcomingHolidaySectionData.trips = upcomingTripsResult?.trips?.map(
          (trip) => parseTripObject(trip)
        );
      })
    );
  }

  let tripTypeArr = [];
  function handleTripTypeSuccessFn(res) {
    resCookies = getApiResponseCookies(res, resCookies);
    tripTypeArr = res.data?.popular_trip_types?.map((tripType) =>
      parseTripTypeObj(tripType)
    );
  }

  let suggestedLocations = [];
  function handleSuggestedLocationsSuccessFn(res) {
    suggestedLocations = res?.data?.locations || [];
  }

  await Promise.allSettled([
    ...categorySectionTripsCalls,
    handleGetApiCall("home/presets", {}, handleTripTypeSuccessFn, null, {
      cookie: cookieString,
    }),
    handleGetApiCall(
      "location/filter-suggestions?type=highlight",
      {},
      handleSuggestedLocationsSuccessFn,
      null,
      {
        cookie: cookieString,
      }
    ),
  ]);

  res.setHeader("set-cookie", resCookies);

  return {
    props: {
      tripTypeArr,
      upcomingTrips: upcomingTrips ?? [],
      // popularLocations: popularLocations?.value ?? [],
      upcomingTripSectionsData,
      upcomingHolidaySectionData,
      suggestedLocations,
    },
  };
}
