import React from "react";
import useIsMobile from "../../utils/device.type.hook";
import { Box, Typography } from "@mui/material";
import ALink from "../ui/link";
import styles from "./location.module.css";
import Image from "next/image";

function LocationCard({
  locationDetail,
  linkCtnClassName = "",
  ctnClassName = "",
}) {
  const isMobile = useIsMobile();

  return (
    <ALink
      href={`/${locationDetail?.urlPath}`}
      aProps={{
        target: isMobile ? "_self" : "_blank",
        className: [styles.locationCard__linkCtn, linkCtnClassName].join(" "),
      }}
    >
      <Box className={[styles.locationCard__ctn, ctnClassName].join(" ")}>
        <Image
          src={`${locationDetail?.coverImage}?tx=c_fill,h_512,w_512`}
          layout="fill"
        />
        <Box className={styles.locationCard__detailCtn}>
          <Typography
            component="h5"
            className={styles.locationCard__detail__name}
          >
            {locationDetail?.name}
          </Typography>
          <Typography className={styles.locationCard__detail__region}>
            {locationDetail?.region}
          </Typography>
        </Box>
      </Box>
    </ALink>
  );
}

export default LocationCard;
