import React from "react";
import { Box, Container, Typography } from "@mui/material";
import FeaturedHostCard from "./featured.host.card";
import styles from "./featured.host.module.css";

function FeaturedHostSection({ hosts = [], wrapperBoxClassName = "" }) {
  if (!hosts.length) return <></>;

  return (
    <Box className={wrapperBoxClassName}>
      <Container>
        <Box className={styles.featuredHostCard__section}>
          <Box className={styles.featuredHostCard__sectionHeaderCtn}>
            <Typography
              component="h2"
              className={styles.featuredHostCard__sectionHeader}
            >
              Featured trip hosts
            </Typography>
            <Typography className={styles.featuredHostCard__sectionSubHeader}>
              Popular and top hosts on Cohyk
            </Typography>
          </Box>

          <Box className={styles.featuredHostCard__cardsCtn}>
            {hosts.map((host, idx) => (
              <FeaturedHostCard key={host?.id ?? idx} hostDetail={host} />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default FeaturedHostSection;
