import React from "react";
import useIsMobile from "../../utils/device.type.hook";
import ALink from "../ui/link";
import { Box, Typography } from "@mui/material";
import Image from "next/image";
import styles from "./trip.type.module.css";

function TripTypeCard({ tripType }) {
  const isMobile = useIsMobile();

  return (
    <ALink
      href={tripType?.tripsUrlPath}
      aProps={{
        target: isMobile ? "_self" : "_blank",
        className: styles.tripTypeCtn__link,
      }}
    >
      <Box className={styles.tripTypeCtn}>
        <Box className={styles.tripType__imgCtn}>
          <Image src={tripType?.imageUrl} layout="fill" />
        </Box>
        <Box>
          <Typography className={styles.tripType__name}>
            {tripType?.name}
          </Typography>
        </Box>
      </Box>
    </ALink>
  );
}

export default TripTypeCard;
