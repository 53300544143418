import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styles from "./content.module.css";
// import useIsMobile from "../../utils/device.type.hook";

const REASONS = [
  "Creating trips on Cohyk is FREE",
  "Get discovered by people around the world",
  "Get tools that help you build and manage your own community",
  "Get enquiries with super high intent",
  "Save your cost on expensive online Ads",
];

export default function WhyCreateTripSection() {
  // const isMobile = useIsMobile();

  return (
    <Box className={styles.why_cohyk_section}>
      <Box>
        {/* {isMobile ? (
          <Typography variant="text-sm-semibold" component={"h2"}>
            Why should you create a <br /> trip on Cohyk?
          </Typography>
        ) : (
          <Typography variant="text-base-semibold" component={"h2"}>
            Why should you create a trip on Cohyk?
          </Typography>
        )} */}
        <ul className={styles.create_trip_reasons}>
          {REASONS.map((reason, i) => (
            <li key={i}>
              <Typography className={styles.create_trip_reason}>
                {reason}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
}
