import React from "react";
import { Box, Container, Typography } from "@mui/material";
import styles from "./stats.section.module.css";

const stats = [
  {
    value: "225+",
    text: "Trip Hosts",
  },
  {
    value: "650+",
    text: "Destinations in India",
  },
  {
    value: "3500+",
    text: "Active Trips",
  },
  {
    value: "20000+",
    text: "Monthly Travellers",
  },
];

function StatBox({ stat }) {
  return (
    <Box className={styles.statBox}>
      <Typography className={styles.statBox__valueText}>
        {stat.value}
      </Typography>
      <Typography className={styles.statBox__text}>{stat.text}</Typography>
    </Box>
  );
}

function StatsSection() {
  return (
    <Box className={styles.container}>
      <Container className={styles.innerContainer}>
        <Box className={styles.topContainer}>
          <Typography className={styles.topContainer__header} component="h2">
            Explore your next trip on Cohyk
          </Typography>
          <Typography className={styles.topContainer__subHeader}>
            Cohyk partners with amazing hosts to make it easy for you to find
            the right partner for your next trip
          </Typography>
        </Box>

        <Box className={styles.bottomContainer}>
          {stats.map((stat, i) => (
            <StatBox key={i} stat={stat} />
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default StatsSection;
