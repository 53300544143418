import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Image from "next/image";
import MsgBubble from "../../../public/images/icons/msg_bubble.svg";
import styles from "./guided.trips.module.css";

const contents = [
  {
    img: "https://cohyk.mo.cloudinary.net/op-v1/images/expert-hosts.png",
    title: "Experienced Hosts",
    description:
      "Travel with well trained, connected & informed team of experts.",
  },
  {
    img: "https://cohyk.mo.cloudinary.net/op-v1/images/just-travel.png",
    title: "No Hassle, Just Travel",
    description:
      "No hassle of booking stays, transportation and food. Just pack your bags and enjoy!",
  },
  {
    img: "https://cohyk.mo.cloudinary.net/op-v1/images/safety-first.png",
    title: "Safety First, Always!",
    description: "Join the adventures and real travel with complete safety.",
  },
  {
    img: "https://cohyk.mo.cloudinary.net/op-v1/images/authentic-experiences.png",
    title: "Authentic Experiences",
    description: "Experience everything local - culture, food and adventures.",
  },
  {
    img: "https://cohyk.mo.cloudinary.net/op-v1/images/chat-with-hosts.png",
    customImg: (
      <>
        <Image
          src="https://cohyk.mo.cloudinary.net/op-v1/images/chat-with-hosts.png"
          layout="fill"
        />
        <Box className={styles.guidedTripCard_imgCtn_chatOverlay}>
          <Box className={styles.guidedTripCard_imgCtn_chatOverlay_bubbleCtn}>
            <Image
              className={styles.guidedTripCard_imgCtn_chatOverlay_bubble}
              src={MsgBubble}
              layout="fill"
            />
          </Box>
        </Box>
      </>
    ),
    title: "Connect With Hosts",
    description:
      "Got questions? Connect with the hosts directly using Cohyk Chat.",
  },
];

function GuidedTripCard({ content, position }) {
  return (
    <Box className={styles.guidedTripCard}>
      <Box className={styles.guidedTripCard_imgCtn}>
        {content.customImg ?? <Image src={content.img} layout="fill" />}
        <Typography className={styles.guidedTripCard_position}>
          {position}
        </Typography>
      </Box>
      <Box className={styles.guidedTripCard_contentCtn}>
        <Typography className={styles.guidedTripCard_title}>
          {content.title}
        </Typography>
        <Typography className={styles.guidedTripCard_description}>
          {content.description}
        </Typography>
      </Box>
    </Box>
  );
}

function GuidedTrips({ wrapperClassName = "" }) {
  return (
    <Box className={wrapperClassName}>
      <Container className={styles.guidedTripsCtn}>
        <Box className={styles.guidedTripsHeadingCtn}>
          <Typography className={styles.guidedTripsHeading}>
            Why choose Cohyk for your trips?
          </Typography>
        </Box>
        <Box className={styles.guidedTripsCardCtn}>
          {contents.map((content, index) => (
            <GuidedTripCard
              key={index}
              content={content}
              position={index + 1}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default GuidedTrips;
