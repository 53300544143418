import * as React from "react";
import style from "./pillsdata.module.css";
import { Box, Chip } from "@mui/material";
import Host from "../../../Host/host";
import Location from "../../../location/location";

export default function FilterSuggestion({
  namekey,
  suggestions,
  suggestionEntity,
  setSuggestionFn,
  selectedEntityId = -1,
  chipClassName = "",
  chipSelectedClassName = "",
}) {
  const [selectedChip, setSelectedChip] = React.useState(-1);
  const clickHandler = (id, suggestion) => {
    setSelectedChip(id);
    let entity = {};
    if (suggestionEntity == "host") {
      entity = new Host(suggestion);
    } else {
      entity = new Location(suggestion);
    }
    setSuggestionFn(entity);
  };

  return (
    <>
      <Box className={style.suggestion_Box}>
        {suggestions?.map((suggestion, index) => (
          <Chip
            key={index}
            className={[
              selectedChip === index || suggestion["id"] == selectedEntityId
                ? [style.host_chip_diffColor, chipSelectedClassName].join(" ")
                : style.host_chip,
              chipClassName,
            ].join(" ")}
            sx={{
              "& .MuiChip-label": {
                paddingLeft: "5px",
                paddingRight: "6px",
              },
            }}
            variant="outlined"
            onClick={() => clickHandler(index, suggestion)}
            label={suggestion[namekey]}
            index={index}
          />
        ))}
      </Box>
    </>
  );
}
