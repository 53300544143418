import React from "react";
import { Stack, Typography } from "@mui/material";
import { captureEvent } from "../../google-analytics/tag";
import { EVENTNAME } from "../../google-analytics/constants";
import ChevronLinkButton from "../../ui/chevron.link.button";
import useIsMobile from "../../../utils/device.type.hook";
import styles from "./info.module.css";

const altLocationTripText = `We are adding more trips from the hosts 
who are working hard to build the right itineraries 
for the season.`;

const altLocationTripLinkText = "See trips to other destinations";

function SeeMoreTrips({
  hrefQueryParams,
  eventSection,
  buttonText = "See more trips",
  showAltText = false,
  altText,
  altLinkText,
  eventName = EVENTNAME.SEE_MORE_TRIPS_CLICK,
  baseLink = "/search/trips",
}) {
  const isMobile = useIsMobile();

  function handleSeeMoreTripsClick() {
    captureEvent(eventName, eventSection);
  }
  if (baseLink.charAt(0) !== "/") {
    baseLink = `/${baseLink}`;
  }

  return (
    <Stack alignItems="center" marginTop="24px" rowGap="8px">
      {showAltText && (
        <Typography
          variant="text-base"
          color="neutral-500.main"
          textAlign="center"
        >
          {altText}
        </Typography>
      )}
      <ChevronLinkButton
        className={styles.seeMoreTripsBtn}
        href={
          !showAltText && hrefQueryParams
            ? `${baseLink}?${hrefQueryParams}`
            : baseLink
        }
        text={showAltText ? altLinkText : buttonText}
        onClick={handleSeeMoreTripsClick}
      />
    </Stack>
  );
}

export default SeeMoreTrips;
export { altLocationTripText, altLocationTripLinkText };
