import { Autocomplete, TextField, Box } from "@mui/material";
import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import style from "./autocomplete.module.css";

function defaultRenderOptionFn(props, option, useOptionKey) {
  return (
    <li key={option?.id} {...props}>
      {option?.searchPhotoURL && (
        <img
          className={option.verifiedHost ? "verified" : ""}
          src={option.searchPhotoURL}
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "100%",
            marginRight: "8px",
          }}
        />
      )}
      {useOptionKey ? option[useOptionKey] : option?.name}
    </li>
  );
}

export default function AutoCompleteComponent({
  options,
  value,
  onSelectFn,
  onChangeFn,
  textFieldLabel,
  useOptionKey,
  filterOptionsFn,
  containerSx,
  renderOption,
  freeSolo = true,
  forcePopupIcon = false,
  disableClearable = false,
  InputAdornmentIcon = null,
  sx = {},
  InputLabelSx = {},
  textFieldSize = "small",
  noOptionsText = null,
}) {
  const [inputVal, setInputVal] = React.useState(
    useOptionKey ? value[useOptionKey] : value?.name
  );

  function handleInputTextChange(e) {
    setInputVal(e.target.value);
  }

  return (
    <>
      <Box className={style.autoComplete_container} sx={containerSx}>
        <Autocomplete
          size="small"
          options={options}
          freeSolo={freeSolo}
          forcePopupIcon={forcePopupIcon}
          value={value}
          onChange={onSelectFn}
          getOptionLabel={(option) =>
            useOptionKey ? option[useOptionKey] : option?.name
          }
          renderOption={(props, option) =>
            renderOption && typeof renderOption === "function"
              ? renderOption(props, option)
              : defaultRenderOptionFn(props, option, useOptionKey)
          }
          renderInput={(params) => (
            <TextField
              className={style.autoComplete_textField}
              {...params}
              // inputRef={(e)=>console.log(e,inputReference)}
              size={textFieldSize}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <InputAdornment position="end">
                      {InputAdornmentIcon ? (
                        InputAdornmentIcon
                      ) : (
                        <SearchIcon color="neutral-400" />
                      )}
                    </InputAdornment>
                  </>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  height: "32px",
                },
                ...(!disableClearable
                  ? {
                      ".MuiAutocomplete-endAdornment": {
                        right: "36px !important",
                      },
                    }
                  : {}),
              }}
              onChange={(e) => {
                handleInputTextChange(e);
                if (typeof onChangeFn === "function") {
                  onChangeFn(e);
                }
              }}
              label={textFieldLabel ? textFieldLabel : "Search"}
              InputLabelProps={{
                sx: { lineHeight: "1.75rem", ...InputLabelSx },
              }}
            />
          )}
          filterOptions={(options, muiTextState) => {
            if (typeof filterOptionsFn === "function") {
              return filterOptionsFn(options, muiTextState, inputVal);
            }
            return options;
          }}
          disableClearable={disableClearable}
          sx={sx}
          noOptionsText={noOptionsText}
        />
      </Box>
    </>
  );
}
