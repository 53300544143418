import { createRef } from "react";
import NavbarLayout from "./navbar.layout";
// import StartLocationSection from "../components/home/startlocation.section";
import SearchFiltersSection from "../components/home/search.filters.section";
import HomeContentSection from "../components/home/content.section";
import UpcomingTripSection from "../components/home/upcoming.trips.section";
import Footer from "../components/footer/footer";
import { Button, Container } from "@mui/material";
import { Box } from "@mui/system";
import HeroDesktop from "../components/hero-desktop/hero.desktop";
import styles from "./home.layout.module.css";
import PopularLocations from "../components/section/popular/popular";
import TestimonalSection from "../components/home/testimonals/testimonals.section";
import useIsMobile from "../utils/device.type.hook";
import UpcomingCategoryTripSection from "../components/home/upcoming.category.trips.section";
import { SECTION } from "../components/google-analytics/constants";
import UpcomingHolidayTripsSection from "../components/home/upcoming.holiday.trips.section";
import StatsSection from "../components/home/stats.section";
import FeaturedHostSection from "../components/Host/featured-host/featured.host.section";
import TripTypeCardSection from "../components/trip/trip.type.card.section";
import { ChevronRight } from "@mui/icons-material";
import GuidedTrips from "../components/home/guided-trips/guided.trips";

export default function HomeLayout(props) {
  const {
    children,
    popularTripActivities,
    tripTypeMap,
    tripTypeArr,
    upcomingTrips,
    popularLocations,
    upcomingCategoryTripsData,
    upcomingHolidayTripsData,
    featuredHosts,
    suggestedLocations,
  } = props;
  const isMobile = useIsMobile();
  const testimonialRef = createRef(null);

  function handleSeeTestimonialClick() {
    if (!testimonialRef.current) return;

    testimonialRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  return (
    <>
      <NavbarLayout
        contrastNav={isMobile}
        tripTypeMap={tripTypeMap}
        tripTypeArr={tripTypeArr}
      >
        {!isMobile && (
          <>
            <HeroDesktop className={styles.heroDesktop}>
              <Box className={styles.home_hero_headerContentCtn}>
                <h1 className={styles.home_hero_heading}>
                  Discover trips and experiences with
                  <br />
                  amazing hosts in India
                </h1>
                <Box className={styles.heroSearchFilters_box}>
                  {/* <Container> */}
                  {/* <StartLocationSection /> */}
                  <SearchFiltersSection
                    popularTripActivities={popularTripActivities}
                    suggestedLocations={suggestedLocations}
                  />
                  {/* </Container> */}
                </Box>
              </Box>
            </HeroDesktop>
            <Box className={styles.home_contentCtn}>
              <Box className={styles.home_contentCtn__sectionsCtn}>
                <Box>
                  <GuidedTrips wrapperClassName={styles.guidedTripsSection} />
                  <TripTypeCardSection
                    tripTypes={tripTypeArr}
                    wrapperBoxClassName={styles.tripTypeCardsSection}
                  />
                </Box>
                {/* <HomeContentSection
                wrapperBoxStyles={styles.wrapper_box}
                wrapperContainerStyles={styles.content_container}
                contentBoxStyles={styles.content_box}
              /> */}
                {upcomingHolidayTripsData.trips.length >= 4 && (
                  <UpcomingHolidayTripsSection
                    trips={upcomingHolidayTripsData.trips}
                    sectionHeader={upcomingHolidayTripsData.sectionHeader}
                    sectionSubHeader={upcomingHolidayTripsData.sectionSubHeader}
                    seeMoreQueryParams={
                      upcomingHolidayTripsData.seeMoreQueryParams
                    }
                  />
                )}
                {upcomingTrips?.length ? (
                  <UpcomingTripSection
                    trips={upcomingTrips}
                    wrapperBoxStyles={styles.upcoming_trips_container}
                    gaSource={SECTION.HOME_PAGE}
                  />
                ) : null}
                {upcomingCategoryTripsData.map(
                  (categoryTripsData, i) =>
                    categoryTripsData.trips?.length >= 4 && (
                      <UpcomingCategoryTripSection
                        key={i}
                        trips={categoryTripsData.trips}
                        sectionHeader={categoryTripsData.sectionHeader}
                        seeMoreQueryParams={
                          categoryTripsData.seeMoreQueryParams
                        }
                        gaSource={SECTION.HOME_PAGE}
                        sectionSubHeader={categoryTripsData.sectionSubHeader}
                        seeMoreBaseSearchLink={categoryTripsData.baseSearchUrl}
                      />
                    )
                )}
                {children && <div>{children}</div>}
                <FeaturedHostSection
                  hosts={featuredHosts}
                  wrapperBoxClassName={styles.featuredHostsSection}
                />

                <PopularLocations
                  locations={popularLocations}
                  wrapperBoxStyles={styles.popularDestination_box}
                  useAltTitleDesign
                />
              </Box>
              <StatsSection />
              <TestimonalSection />
              <HomeContentSection
                wrapperBoxStyles={styles.wrapper_box}
                wrapperContainerStyles={styles.content_container}
                contentBoxStyles={styles.content_box}
              />
            </Box>
            <Footer />
          </>
        )}
        {isMobile && (
          <>
            <Container>
              <h1 className={styles.home_hero_heading}>
                Discover trips and experiences
                <br />
                with amazing hosts in India
              </h1>
              {/* <StartLocationSection /> */}
              <SearchFiltersSection
                suggestedLocations={suggestedLocations}
                // Disabling prop since trip categories are taken off of home page
                // popularTripActivities={popularTripActivities}
              />
              <Button
                className={styles.seeTestimonialsBtn}
                onClick={handleSeeTestimonialClick}
              >
                See what travellers are saying about us
                <ChevronRight
                  className={styles.seeTestimonialsBtn__chevron}
                  color="primary"
                />
              </Button>
            </Container>
            <Box className={styles.home_contentCtn}>
              <Box className={styles.home_contentCtn__sectionsCtn}>
                <Box>
                  <GuidedTrips wrapperClassName={styles.guidedTripsSection} />
                  <TripTypeCardSection
                    wrapperBoxClassName={styles.tripTypeCardsSection}
                    tripTypes={tripTypeArr}
                  />
                </Box>
                {upcomingHolidayTripsData.trips.length >= 4 && (
                  <UpcomingHolidayTripsSection
                    trips={upcomingHolidayTripsData.trips}
                    sectionHeader={upcomingHolidayTripsData.sectionHeader}
                    sectionSubHeader={upcomingHolidayTripsData.sectionSubHeader}
                    seeMoreQueryParams={
                      upcomingHolidayTripsData.seeMoreQueryParams
                    }
                  />
                )}
                {upcomingTrips?.length ? (
                  <UpcomingTripSection
                    trips={upcomingTrips}
                    wrapperBoxStyles={styles.upcoming_trips_container_mweb}
                    gaSource={SECTION.HOME_PAGE}
                  />
                ) : null}
                {upcomingCategoryTripsData.map(
                  (categoryTripsData, i) =>
                    categoryTripsData.trips?.length >= 4 && (
                      <UpcomingCategoryTripSection
                        key={i}
                        trips={categoryTripsData.trips}
                        sectionHeader={categoryTripsData.sectionHeader}
                        seeMoreQueryParams={
                          categoryTripsData.seeMoreQueryParams
                        }
                        gaSource={SECTION.HOME_PAGE}
                        sectionSubHeader={categoryTripsData.sectionSubHeader}
                        seeMoreBaseSearchLink={categoryTripsData.baseSearchUrl}
                      />
                    )
                )}
                <FeaturedHostSection
                  hosts={featuredHosts}
                  wrapperBoxClassName={styles.featuredHostsSection}
                />
                <PopularLocations
                  locations={popularLocations}
                  wrapperBoxStyles={styles.popularDestination_box_mweb}
                  useAltTitleDesign
                />
              </Box>
              {/* <HomeContentSection
                wrapperContainerStyles={styles.content_container_mweb}
                contentBoxStyles={styles.content_box_mweb}
              /> */}
            </Box>
            <StatsSection />
            <TestimonalSection
              className={styles.testimonialsSection}
              ref={testimonialRef}
            />
            <div>{children}</div>
            <HomeContentSection
              wrapperContainerStyles={styles.content_container_mweb}
              contentBoxStyles={styles.content_box_mweb}
            />
            <Footer />
          </>
        )}
      </NavbarLayout>
    </>
  );
}
