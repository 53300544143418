import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import styles from "./content.module.css";
import Image from "next/image";
import WhyCreateTripSection from "./why-create-trip.section";
import { EVENTNAME, SECTION } from "../google-analytics/constants";
import { captureEvent } from "../google-analytics/tag";
import { useDispatch } from "react-redux";

export default function HomeContentSection({
  wrapperBoxStyles,
  wrapperContainerStyles,
  contentBoxStyles,
}) {
  const dispatch = useDispatch();
  const handleRegisterClick = () => {
    captureEvent(EVENTNAME.ADD_TRIP_CLICK, SECTION.HOME_ADD_TRIP);
    dispatch({ type: "OPEN_HOST_REGISTER_MODAL" });
  };

  return (
    <Box
      className={[wrapperBoxStyles, styles.create_trip__outer_container].join(
        " "
      )}
    >
      <Container className={wrapperContainerStyles}>
        <Container
          className={[contentBoxStyles, styles.create_trip__container].join(
            " "
          )}
        >
          <Box className={styles.create_trip_ctn}>
            <Box className={styles.create_trip__left_ctn}>
              <Box>
                <Typography
                  className={styles.create_trip__header}
                  component="h2"
                >
                  Find travelers for trips
                </Typography>
                <Typography className={styles.create_trip__subheader}>
                  Cohyk is the best place to promote trips and let travelers
                  find you.
                </Typography>
              </Box>
              <WhyCreateTripSection />
              <Button
                className={styles.content_addTrip_btn}
                onClick={handleRegisterClick}
              >
                Become a Host
              </Button>
            </Box>
            <Box className={styles.create_trip__right_ctn}>
              <Image
                src="https://cohyk.mo.cloudinary.net/op-v1/images/analytics-dashboard.png"
                layout="fill"
              />
            </Box>
          </Box>
        </Container>
      </Container>
    </Box>
  );
}
